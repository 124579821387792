import connectorNodeV1 from '@opuscapita/react-filemanager-connector-node-v1';

export default {
  filemanager: {
    apiOptions: {
      ...connectorNodeV1.apiOptions,
      apiRoot: 'https://fileserver.cnf.stage.de.dol.it'
    }
  },
  wpRestUrl: 'https://cnf.stage.de.dol.it/wp-json'
}