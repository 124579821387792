import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight} from '@fortawesome/free-solid-svg-icons';
import { DettRow, DettDetail } from './doc-simple.js';
import $ from "jquery";
class ScuolaLavoro extends Component {
    render() {
        return (
          <div className="section">
            <div className="row">
                <div className="col-12">
                <div className="text-center header-box">
                    <h3>Progetto</h3>
                    <h2>Alternanza Scuola Lavoro</h2>
                  </div>
                </div>
            </div>
            <div className="content-section">
              <div className="row">
                <div className="col-8 mb-3">
                  <p>
                    In questa sezione sono disponibili indicazioni e contributi per l'organizzazione degli incontri e laboratori con gli alunni che svolgono il percorso di 100 ore di alternanza scuola | lavoro riconosciuto dal MIUR (allegato 1 al Protocollo d'intesa del 3 ottobre 2016).
                  </p>
                  <a href="/alternanza-scuola-lavoro" className="btn btn-info">Leggi Tutto &gt;</a>
                </div>
              </div>
              <div className="row">
                <div className="col-12 tit-box">
                  Strumenti didattici per i 4 laboratori
                </div>
              </div>
              <div className="row">
                <div className="col-4 d-flex mb-3">
                  <a href="/alternanza-scuola-lavoro/guida-giuridica" className="btn btn-utility btn-block">
                    <div className="row">
                      <div className="col-lg-10 text-lg-left text-center">
                        Guida giuridica
                      </div>
                      <div className="col-lg-2 text-lg-right text-center">
                        <FontAwesomeIcon icon={faLongArrowAltRight} />
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-4 d-flex mb-3">
                  <a href="/alternanza-scuola-lavoro/reportage-giuridico" className="btn btn-utility btn-block">
                    <div className="row">
                      <div className="col-lg-10 text-lg-left text-center">
                        Reportage giuridico
                      </div>
                      <div className="col-lg-2 text-lg-right text-center">
                        <FontAwesomeIcon icon={faLongArrowAltRight} />
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-4 d-flex mb-3">
                  <a href="/alternanza-scuola-lavoro/start-up" className="btn btn-utility btn-block">
                    <div className="row">
                      <div className="col-lg-10 text-lg-left text-center">
                        Start-up
                      </div>
                      <div className="col-lg-2 text-lg-right text-center">
                        <FontAwesomeIcon icon={faLongArrowAltRight} />
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-4 d-flex mb-3">
                  <a href="/alternanza-scuola-lavoro/studio-legale-in-aula" className="btn btn-utility btn-block">
                    <div className="row">
                      <div className="col-lg-10 text-lg-left text-center">
                        Studio legale in aula
                      </div>
                      <div className="col-lg-2 text-lg-right text-center">
                        <FontAwesomeIcon icon={faLongArrowAltRight} />
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-4 d-flex mb-3">
                  <a href="/alternanza-scuola-lavoro/esperienze" className="btn btn-utility btn-block">
                    <div className="row">
                      <div className="col-lg-10 text-lg-left text-center">
                        Esperienze
                      </div>
                      <div className="col-lg-2 text-lg-right text-center">
                        <FontAwesomeIcon icon={faLongArrowAltRight} />
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-4 d-flex mb-3">
                  <a href="/alternanza-scuola-lavoro/proposte" className="btn btn-utility btn-block">
                    <div className="row">
                      <div className="col-lg-10 text-lg-left text-center">
                        Proposte
                      </div>
                      <div className="col-lg-2 text-lg-right text-center">
                        <FontAwesomeIcon icon={faLongArrowAltRight} />
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        );
    }
}

class AslContent extends Component {
    render() {
        return (
          <div className="App scuola-lavoro">
            <div className="container">
              <div className="row">
                  <div className="col-12">
                    <div className="text-center header-box">
                      <h3>Progetto</h3>
                      <h2>Alternanza Scuola Lavoro</h2>
                    </div>
                  </div>
              </div>
              <div className="content-section">
                <div className="row">
                  <div className="col-12 mb-3">
                    <h3>Strumenti didattici, proposte operative e materiale di lavoro</h3>
                    <p>
                      In questa sezione sono disponibili indicazioni e contributi per l'organizzazione degli incontri e laboratori con gli alunni che svolgono il percorso di 100 ore di&nbsp;alternanza scuola | lavoro&nbsp;riconosciuto dal MIUR (allegato 1 al Protocollo d'intesa&nbsp;del&nbsp;3 ottobre 2016).&nbsp;</p> <p>Le attività sono articolate&nbsp;sulla base delle attività previste dai <strong>4&nbsp;laboratori</strong> proposti:
                    </p>
                    <p>
                      <span>Guida giuridica&nbsp;</span>&nbsp; &nbsp;<span>Start-up&nbsp;</span>&nbsp; &nbsp;<span>Studio legale in aula</span>
                    </p>
                    <p>
                      I temi proposti nell’intero corso di 100 ore sono esposti in modo sequenziale ma&nbsp;ognuno dei <strong>25 incontri</strong> consente&nbsp;una trattazione&nbsp;reticolare&nbsp;rispetto a gli altri temi per&nbsp;offrire&nbsp;agli alunni una visione complessiva.</p> <p>Quindi, si suggerisce di affrontare in un primo momento i temi, quesiti e problemi che emergono dall’intestazione dell’incontro e, successivamente,&nbsp;trattare&nbsp;la rilevanza di quanto esposto rispetto agli altri incontri e alle tematiche trattate.&nbsp;
                    </p>
                    <p>
                      Un esempio metodologico è disponibile negli incontri 1 e 2.&nbsp;
                    </p>
                    <p>
                      Le <strong>fasi dell'attività di laboratorio</strong>&nbsp;(contraddistinte&nbsp;da lettere)&nbsp;si intersecano con&nbsp;gli incontri tematici consentento l'approfondimento&nbsp;in relazione allo specifico laboratorio scelto dagli alunni all'inizio del percorso. Per esempio,&nbsp;<span>agli studenti impegnati nel laboratorio Studio legale in aula&nbsp;</span>negli incontri n. 16, 17, 18 e 19 potranno essere presentati aspetti più di dettaglio dell'attività professionale come quelli deontologici.
                    </p>
                    <div>
                      Queste le categorie dei contributi proposti:&nbsp;
                    </div>
                    <div>
                      <code>attività in aula</code>
                    </div>
                    <div>
                      <code>conoscenze e abilità</code>&nbsp;
                    </div>
                    <div>
                      <code>competenze&nbsp;trasversali</code>
                    </div>
                    <div>
                      <span>modalità di lavoro</span>
                    </div>
                    <div>
                      <code>indicazioni</code>
                    </div>
                    <div>
                      <code>testi input:&nbsp;contributi CNF e Fondazioni</code> &nbsp;
                    </div>
                    <div>
                      <code>testi input: Il Dubbio</code>
                    </div>
                    <div>
                      <code>strumenti didattici</code>
                    </div>
                    <div>
                      <code>sussidi didattici: film video libro canzone news</code>
                    </div>
                    <div>
                      <p>
                        L'area riservata sarà costantemente arricchita anche con i contributi che i referenti potranno segnalare scrivendo a&nbsp;&nbsp;
                        <strong>
                          <em>
                            <a href="mailto:progettoasl@consiglionazionaleforense.it">progettoasl@consiglionazionaleforense.it</a>
                          </em>
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 tit-box">
                    Strumenti didattici per i 4 laboratori
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 d-flex mb-3">
                    <a href="/alternanza-scuola-lavoro/guida-giuridica" className="btn btn-utility btn-block">
                      <div className="row">
                        <div className="col-lg-10 text-lg-left text-center">
                          Guida giuridica
                        </div>
                        <div className="col-lg-2 text-lg-right text-center">
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-4 d-flex mb-3">
                    <a href="/alternanza-scuola-lavoro/reportage-giuridico" className="btn btn-utility btn-block">
                      <div className="row">
                        <div className="col-lg-10 text-lg-left text-center">
                          Reportage giuridico
                        </div>
                        <div className="col-lg-2 text-lg-right text-center">
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-4 d-flex mb-3">
                    <a href="/alternanza-scuola-lavoro/start-up" className="btn btn-utility btn-block">
                      <div className="row">
                        <div className="col-lg-10 text-lg-left text-center">
                          Start-up
                        </div>
                        <div className="col-lg-2 text-lg-right text-center">
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-4 d-flex mb-3">
                    <a href="/alternanza-scuola-lavoro/studio-legale-in-aula" className="btn btn-utility btn-block">
                      <div className="row">
                        <div className="col-lg-10 text-lg-left text-center">
                          Studio legale in aula
                        </div>
                        <div className="col-lg-2 text-lg-right text-center">
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-4 d-flex mb-3">
                    <a href="/alternanza-scuola-lavoro/esperienze" className="btn btn-utility btn-block">
                      <div className="row">
                        <div className="col-lg-10 text-lg-left text-center">
                          Esperienze
                        </div>
                        <div className="col-lg-2 text-lg-right text-center">
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-4 d-flex mb-3">
                    <a href="/alternanza-scuola-lavoro/proposte" className="btn btn-utility btn-block">
                      <div className="row">
                        <div className="col-lg-10 text-lg-left text-center">
                          Proposte
                        </div>
                        <div className="col-lg-2 text-lg-right text-center">
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}


class AslGuidaGiuridica extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docsAsl: [
              {
                  id: 10,
                  title: '22 febbraio 2019 - fascicolo seduta amministrativa'
              },
              {
                  id: 20,
                  title: '10 febbraio 2019 - fascicolo seduta amministrativa'
              },
              {
                  id: 22,
                  title: '32 febbraio 2019 - fascicolo seduta amministrativa'
              }
            ]
        };
    }
    componentDidMount() {
      $.ajax('https://cnf.stage.de.dol.it/wp-json/wp/v2/categories?slug=laboratori,guida-giuridica', {
        success: (response) => {
          if(response.length != 2)
          {
            console.log('NOBUONO');
          }
          else
          {
            $.ajax('https://cnf.stage.de.dol.it/wp-json/wp/v2/posts?per_page=100&categories=' + response[0].id + ',' + response[1].id, {
              success: (response) => {
                console.log(response);
                this.setState({docsAsl: response});
              }
            });
          }
        }
      });
    }
    render() {
      const baseUrl = '/alternanza-scuola-lavoro/guida-giuridica/'
        return (
          <div className="App scuola-lavoro">
            <div className="container">
              <div className="row">
                  <div className="col-12">
                    <div className="text-center header-box">
                      <h3>Alternanza Scuola Lavoro</h3>
                      <h2>Guida Giuridica</h2>
                    </div>
                  </div>
              </div>
              <div className="content-section">
                {this.state.docsAsl.map((docAsl, i) => <DettRow key={docAsl.id} urlInfo={baseUrl} id={docAsl.id} title={docAsl.title.rendered} />)}
              </div>
            </div>
          </div>
        );
    }
}

class AslGuidaGiuridicaDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docAsl: {
                title: 'Pippo',
                content: 'Lorem ipsum sit dolor amet'
            }
        };
    }
    componentDidMount() {
      $.ajax('https://cnf.stage.de.dol.it/wp-json/wp/v2/posts/' + this.props.docId, {
        success: (response) => {
          console.log(response);
          this.setState({
            docAsl: response
          });
        }
      });
    }
    render() {
        return (
          <div className="App scuola-lavoro">
            <div className="container">
              <div className="row">
                  <div className="col-12">
                    <div className="text-center header-box">
                      <h3>Alternanza Scuola Lavoro</h3>
                      <h2>Guida Giuridica</h2>
                    </div>
                  </div>
              </div>
              <div className="content-section">
                  <DettDetail content={this.state.docAsl.content.rendered} title={this.state.docAsl.title.rendered} />
              </div>
            </div>
          </div>
        );
    }
}


class AslReportageGiuridico extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docsAsl: [
              {
                  id: 10,
                  title: '22 febbraio 2019 - fascicolo seduta amministrativa'
              },
              {
                  id: 20,
                  title: '10 febbraio 2019 - fascicolo seduta amministrativa'
              },
              {
                  id: 22,
                  title: '32 febbraio 2019 - fascicolo seduta amministrativa'
              }
            ]
        };
    }
    componentDidMount() {
      $.ajax('https://cnf.stage.de.dol.it/wp-json/wp/v2/categories?slug=laboratori,reportage-giuridico', {
        success: (response) => {
          if(response.length != 2)
          {
            console.log('NOBUONO');
          }
          else
          {
            $.ajax('https://cnf.stage.de.dol.it/wp-json/wp/v2/posts?per_page=100&categories=' + response[0].id + ',' + response[1].id, {
              success: (response) => {
                console.log(response);
                this.setState({docsAsl: response});
              }
            });
          }
        }
      });
    }
    render() {
      const baseUrl = '/alternanza-scuola-lavoro/reportage-giuridico/'
        return (
          <div className="App scuola-lavoro">
            <div className="container">
              <div className="row">
                  <div className="col-12">
                    <div className="text-center header-box">
                      <h3>Alternanza Scuola Lavoro</h3>
                      <h2>Reportage giuridico</h2>
                    </div>
                  </div>
              </div>
              <div className="content-section">
                  {this.state.docsAsl.map((docAsl, i) => <DettRow key={docAsl.id} urlInfo={baseUrl} id={docAsl.id} title={docAsl.title.rendered} />)}
              </div>
            </div>
          </div>
        );
    }
}

class AslReportageGiuridicoDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docAsl: {
                title: 'Pippo',
                content: 'Lorem ipsum sit dolor amet'
            }
        };
    }
    componentDidMount() {
      $.ajax('https://cnf.stage.de.dol.it/wp-json/wp/v2/posts/' + this.props.docId, {
        success: (response) => {
          console.log(response);
          this.setState({
            docAsl: response
          });
        }
      });
    }
    render() {
        return (
          <div className="App scuola-lavoro">
            <div className="container">
              <div className="row">
                  <div className="col-12">
                    <div className="text-center header-box">
                      <h3>Alternanza Scuola Lavoro</h3>
                      <h2>Reportage Giuridico</h2>
                    </div>
                  </div>
              </div>
              <div className="content-section">
                  <DettDetail content={this.state.docAsl.content.rendered} title={this.state.docAsl.title.rendered} />
              </div>
            </div>
          </div>
        );
    }
}

class AslStartUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docsAsl: [
              {
                  id: 10,
                  title: '22 febbraio 2019 - fascicolo seduta amministrativa'
              },
              {
                  id: 20,
                  title: '10 febbraio 2019 - fascicolo seduta amministrativa'
              },
              {
                  id: 22,
                  title: '32 febbraio 2019 - fascicolo seduta amministrativa'
              }
            ]
        };
    }
    componentDidMount() {
      $.ajax('https://cnf.stage.de.dol.it/wp-json/wp/v2/categories?slug=laboratori,start-up', {
        success: (response) => {
          if(response.length != 2)
          {
            console.log('NOBUONO');
          }
          else
          {
            $.ajax('https://cnf.stage.de.dol.it/wp-json/wp/v2/posts?per_page=100&categories=' + response[0].id + ',' + response[1].id, {
              success: (response) => {
                console.log(response);
                this.setState({docsAsl: response});
              }
            });
          }
        }
      });
    }
    render() {
      const baseUrl = '/alternanza-scuola-lavoro/startup/'
        return (
          <div className="App scuola-lavoro">
            <div className="container">
              <div className="row">
                  <div className="col-12">
                    <div className="text-center header-box">
                      <h3>Alternanza Scuola Lavoro</h3>
                      <h2>Start-up</h2>
                    </div>
                  </div>
              </div>
              <div className="content-section">
                  {this.state.docsAsl.map((docAsl, i) => <DettRow key={docAsl.id} urlInfo={baseUrl} id={docAsl.id} title={docAsl.title.rendered} />)}
              </div>
            </div>
          </div>
        );
    }
}

class AslStartUpDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docAsl: {
                title: 'Pippo',
                content: 'Lorem ipsum sit dolor amet'
            }
        };
    }
    componentDidMount() {
      $.ajax('https://cnf.stage.de.dol.it/wp-json/wp/v2/posts/' + this.props.docId, {
        success: (response) => {
          console.log(response);
          this.setState({
            docAsl: response
          });
        }
      });
    }
    render() {
        return (
          <div className="App scuola-lavoro">
            <div className="container">
              <div className="row">
                  <div className="col-12">
                    <div className="text-center header-box">
                      <h3>Alternanza Scuola Lavoro</h3>
                      <h2>Start-up</h2>
                    </div>
                  </div>
              </div>
              <div className="content-section">
                  <DettDetail content={this.state.docAsl.content.rendered} title={this.state.docAsl.title.rendered} />
              </div>
            </div>
          </div>
        );
    }
}

class AslStudioLegaleInAula extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docsAsl: [
              {
                  id: 10,
                  title: '22 febbraio 2019 - fascicolo seduta amministrativa'
              },
              {
                  id: 20,
                  title: '10 febbraio 2019 - fascicolo seduta amministrativa'
              },
              {
                  id: 22,
                  title: '32 febbraio 2019 - fascicolo seduta amministrativa'
              }
            ]
        };
    }
    componentDidMount() {
      $.ajax('https://cnf.stage.de.dol.it/wp-json/wp/v2/categories?slug=laboratori,studio-legale-in-aula', {
        success: (response) => {
          if(response.length != 2)
          {
            console.log('NOBUONO');
          }
          else
          {
            $.ajax('https://cnf.stage.de.dol.it/wp-json/wp/v2/posts?per_page=100&categories=' + response[0].id + ',' + response[1].id, {
              success: (response) => {
                console.log(response);
                this.setState({docsAsl: response});
              }
            });
          }
        }
      });
    }
    render() {
      const baseUrl = '/alternanza-scuola-lavoro/studio-legale-in-aula/'
        return (
          <div className="App scuola-lavoro">
            <div className="container">
              <div className="row">
                  <div className="col-12">
                    <div className="text-center header-box">
                      <h3>Alternanza Scuola Lavoro</h3>
                      <h2>Studio legale in aula</h2>
                    </div>
                  </div>
              </div>
              <div className="content-section">
                  {this.state.docsAsl.map((docAsl, i) => <DettRow key={docAsl.id} urlInfo={baseUrl} id={docAsl.id} title={docAsl.title.rendered} />)}
              </div>
            </div>
          </div>
        );
    }
}

class AslStudioLegaleInAulaDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docAsl: {
                title: 'Pippo',
                content: 'Lorem ipsum sit dolor amet'
            }
        };
    }
    componentDidMount() {
      $.ajax('https://cnf.stage.de.dol.it/wp-json/wp/v2/posts/' + this.props.docId, {
        success: (response) => {
          console.log(response);
          this.setState({
            docAsl: response
          });
        }
      });
    }
    render() {
        return (
          <div className="App scuola-lavoro">
            <div className="container">
              <div className="row">
                  <div className="col-12">
                    <div className="text-center header-box">
                      <h3>Alternanza Scuola Lavoro</h3>
                      <h2>Studio legale in aula</h2>
                    </div>
                  </div>
              </div>
              <div className="content-section">
                  <DettDetail content={this.state.docAsl.content.rendered} title={this.state.docAsl.title.rendered} />
              </div>
            </div>
          </div>
        );
    }
}

class AslEsperienze extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docsAsl: [
              {
                  id: 10,
                  title: '22 febbraio 2019 - fascicolo seduta amministrativa'
              },
              {
                  id: 20,
                  title: '10 febbraio 2019 - fascicolo seduta amministrativa'
              },
              {
                  id: 22,
                  title: '32 febbraio 2019 - fascicolo seduta amministrativa'
              }
            ]
        };
    }
    componentDidMount() {
      $.ajax('https://cnf.stage.de.dol.it/wp-json/wp/v2/posts?include=13895', {
        success: (response) => {
          if(response.length != 1)
          {
            console.log('NOBUONO');
          }
          else
          {
            console.log(response);
            this.setState({docsAsl: response});
          }
        }
      });
    }
    render() {
      const baseUrl = '/alternanza-scuola-lavoro/esperienze/'
        return (
          <div className="App scuola-lavoro">
            <div className="container">
              <div className="row">
                  <div className="col-12">
                    <div className="text-center header-box">
                      <h3>Alternanza Scuola Lavoro</h3>
                      <h2>Esperienze</h2>
                    </div>
                  </div>
              </div>
              <div className="content-section">
                  {this.state.docsAsl.map((docAsl, i) => <DettRow key={docAsl.id} urlInfo={baseUrl} id={docAsl.id} title={docAsl.title.rendered} />)}
              </div>
            </div>
          </div>
        );
    }
}

class AslEsperienzeDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docAsl: {
                title: 'Pippo',
                content: 'Lorem ipsum sit dolor amet'
            }
        };
    }
    componentDidMount() {
      $.ajax('https://cnf.stage.de.dol.it/wp-json/wp/v2/posts/' + this.props.docId, {
        success: (response) => {
          console.log(response);
          this.setState({
            docAsl: response
          });
        }
      });
    }
    render() {
        return (
          <div className="App scuola-lavoro">
            <div className="container">
              <div className="row">
                  <div className="col-12">
                    <div className="text-center header-box">
                      <h3>Alternanza Scuola Lavoro</h3>
                      <h2>Esperienze</h2>
                    </div>
                  </div>
              </div>
              <div className="content-section">
                  <DettDetail content={this.state.docAsl.content.rendered} title={this.state.docAsl.title.rendered} />
              </div>
            </div>
          </div>
        );
    }
}

class AslProposte extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docsAsl: [
              {
                  id: 10,
                  title: '22 febbraio 2019 - fascicolo seduta amministrativa'
              },
              {
                  id: 20,
                  title: '10 febbraio 2019 - fascicolo seduta amministrativa'
              },
              {
                  id: 22,
                  title: '32 febbraio 2019 - fascicolo seduta amministrativa'
              }
            ]
        };
    }
    componentDidMount() {
      $.ajax('https://cnf.stage.de.dol.it/wp-json/wp/v2/posts?include=14150,13945', {
        success: (response) => {
          if(response.length != 2)
          {
            console.log('NOBUONO');
          }
          else
          {
            console.log(response);
            this.setState({docsAsl: response});
          }
        }
      });
    }
    render() {
      const baseUrl = '/alternanza-scuola-lavoro/proposte/'
        return (
          <div className="App scuola-lavoro">
            <div className="container">
              <div className="row">
                  <div className="col-12">
                    <div className="text-center header-box">
                      <h3>Alternanza Scuola Lavoro</h3>
                      <h2>Proposte</h2>
                    </div>
                  </div>
              </div>
              <div className="content-section">
                  {this.state.docsAsl.map((docAsl, i) => <DettRow key={docAsl.id} urlInfo={baseUrl} id={docAsl.id} title={docAsl.title.rendered} />)}
              </div>
            </div>
          </div>
        );
    }
}

class AslProposteDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docAsl: {
                title: 'Pippo',
                content: 'Lorem ipsum sit dolor amet'
            }
        };
    }
    componentDidMount() {
      $.ajax('https://cnf.stage.de.dol.it/wp-json/wp/v2/posts/' + this.props.docId, {
        success: (response) => {
          console.log(response);
          this.setState({
            docAsl: response
          });
        }
      });
    }
    render() {
        return (
          <div className="App scuola-lavoro">
            <div className="container">
              <div className="row">
                  <div className="col-12">
                    <div className="text-center header-box">
                      <h3>Alternanza Scuola Lavoro</h3>
                      <h2>Proposte</h2>
                    </div>
                  </div>
              </div>
              <div className="content-section">
                  <DettDetail content={this.state.docAsl.content.rendered} title={this.state.docAsl.title.rendered} />
              </div>
            </div>
          </div>
        );
    }
}



export default ScuolaLavoro;
export { ScuolaLavoro, AslContent, AslGuidaGiuridica, AslGuidaGiuridicaDetail, AslReportageGiuridico, AslReportageGiuridicoDetail, AslStartUp, AslStartUpDetail, AslStudioLegaleInAula, AslStudioLegaleInAulaDetail, AslEsperienze, AslEsperienzeDetail, AslProposte, AslProposteDetail};
