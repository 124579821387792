import React, { Component } from 'react';
import SeduteAmministrative from './sedute-amministrative.js';
import VerbaliCommissioni from './verbali-commissioni.js';
import ScuolaLavoro from './alternanza-scuola-lavoro.js';
import Onpg from './onpg.js';

class Home extends Component {
    render() {
        return (
          <div className="container">
            <div className="row">
              <div className="col-md-6 sedute-amministrative">
                <SeduteAmministrative />
              </div>
              <div className="col-md-6 verbali-commissioni">
                <VerbaliCommissioni />
              </div>
            </div>
            <div className="onpg">
              <Onpg />
            </div>
            <div className="scuola-lavoro">
              <ScuolaLavoro />
            </div>
          </div>
        );
    }
}

export default Home;
