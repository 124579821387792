import React, { Component } from 'react';
import bookoblique from './icon/ico_cnf_ext-17.svg';
import book from './icon/ico_cnf_ext-08.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { DettRow, DettDetail } from './doc-simple.js';
import { FileNavigator, FileManager } from '@opuscapita/react-filemanager';
import connectorNodeV1 from '@opuscapita/react-filemanager-connector-node-v1';
import $ from 'jquery';
import config from './config/config';

class SeduteAmministrative extends Component {
    render() {
        return (
            <div className="section">
                <div className="col-12 text-center header-box">
                    <h2>Sedute Amministrative</h2>
                </div>
                <div className="content-section">
                    <a href="/seduteamministrative/fascicoli" className="row align-items-center">
                        <div className="col-md-2">
                            <img src={bookoblique} className="ico-standard" alt="Icona libro" />
                        </div>
                        <div className="col-md-9 px-md-0">
                            <h4>Fascicoli</h4>
                            Tutti i fascicoli delle sedute amministrative
                        </div>
                        <div className="col-md-1">
                            <FontAwesomeIcon icon={faLongArrowAltRight} className="ico-standard" />
                        </div>
                    </a>
                    <hr></hr>
                    <a href="/seduteamministrative/bozze" className="row align-items-center">
                        <div className="col-md-2">
                            <img src={book} className="ico-standard" alt="Icona libro" />
                        </div>
                        <div className="col-md-9 px-md-0">
                            <h4>Bozze dei verbali provvisori</h4>
                        </div>
                        <div className="col-md-1">
                            <FontAwesomeIcon icon={faLongArrowAltRight} className="ico-standard" />
                        </div>
                    </a>
                </div>
            </div>
        );
    }
}

class FascicoliSeduteAmministrative extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fascicoli: [
                {
                    id: 10,
                    title: '22 febbraio 2019 - fascicolo seduta amministrativa'
                },
                {
                    id: 20,
                    title: '10 febbraio 2019 - fascicolo seduta amministrativa'
                },
                {
                    id: 22,
                    title: '32 febbraio 2019 - fascicolo seduta amministrativa'
                }
            ]
        };
    }
    componentDidMount() {
      $.ajax(config.wpRestUrl + '/wp/v2/categories?slug=fascicoli-sedute', {
        success: (response) => {
          if(response.length != 1)
          {
            console.log('NOBUONO');
          }
          else
          {
            const category = response[0];
            $.ajax(config.wpRestUrl + '/wp/v2/posts?per_page=100&categories=' + category.id, {
              success: (response) => {
                console.log(response);
                this.setState({fascicoli: response});
              }
            });
          }
        }
      });
    }
    render() {
      const baseUrl = '/seduteamministrative/fascicoli/';
        return (
          <div className="App sedute-amministrative">
            <div className="container">
              <div className="row">
                  <div className="col-12">
                    <div className="text-center header-box">
                      <h3>Sedute Amministrative</h3>
                      <h2>Fascicoli</h2>
                    </div>
                  </div>
              </div>
              <div className="content-section">
                {this.state.fascicoli.map((fascicolo, i) => <DettRow urlInfo={baseUrl} id={fascicolo.id} title={fascicolo.title.rendered} />)}
              </div>
            </div>
          </div>
        );
    }
}

class FascicoloDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fascicolo: {
                title: 'Title',
                content: 'Lorem ipsum sit dolor amet'
            }
        };
    }
    componentDidMount() {
      $.ajax(config.wpRestUrl + '/wp/v2/posts/' + this.props.fascicoloId, {
        success: (response) => {
          console.log(response);
          this.setState({
            fascicolo: response
          });
        }
      });
    }
    render() {
        return (
          <div className="App sedute-amministrative">
            <div className="container">
              <div className="row">
                  <div className="col-12">
                    <div className="text-center header-box">
                      <h3>Sedute Amministrative</h3>
                      <h2>Fascicoli</h2>
                    </div>
                  </div>
              </div>
              <div className="content-section">
                <DettDetail content={this.state.fascicolo.content.rendered} title={this.state.fascicolo.title.rendered} />
              </div>
            </div>
          </div>
        );
    }
}

class BozzeFascicoliSeduteAmministrative extends Component {
  render() {
    const rootId = 'L2NuZi9BcmVhIHJpc2VydmF0YS9Cb3p6ZSBkZWkgdmVyYmFsaSBwcm92dmlzb3JpIGRlbGxlIHNlZHV0ZSBhbW1pbmlzdHJhdGl2ZQ';
    return(
      <div className="App sedute-amministrative">
        <div className="container">
          <div className="row">
              <div className="col-12">
                <div className="text-center header-box">
                  <h3>Sedute Amministrative</h3>
                  <h2>Bozze dei verbali provvisori</h2>
                </div>
              </div>
          </div>
          <div className="content-section">
            <div style={{ height: '480px' }}>
              <FileManager>
                <FileNavigator
                  id="filemanager-1"
                  api={connectorNodeV1.api}
                  apiOptions={config.filemanager.apiOptions}
                  initialResourceId={rootId}
                  capabilities={connectorNodeV1.capabilities}
                  listViewLayout={connectorNodeV1.listViewLayout}
                  viewLayoutOptions={connectorNodeV1.viewLayoutOptions}
                  onResourceItemDoubleClick={
                    ({event, number, rowData}) => {
                      const isFile = rowData.type === 'file';

                      if(isFile)
                      {
                        const downloadUrl = `${config.filemanager.apiOptions.apiRoot}/download?items=${rowData.id}`;
      //                  const name = rowData.name;
      //                  if (downloadUrl) {
      //                    var iframeId = 'cnf-iframe-downloader';
      //                    var iframeDOMNode = document.getElementById(iframeId);
      //
      //                    if (!iframeDOMNode) {
      //                      iframeDOMNode = document.createElement('iframe');
      //                      iframeDOMNode.style.display = 'none';
      //                      iframeDOMNode.id = iframeId;
      //                      document.body.appendChild(iframeDOMNode);
      //                    }
      //
      //                    iframeDOMNode.src = downloadUrl;
      //                  }
                        window.open(downloadUrl + '&preview=true', '_blank');
                      }
                    }
                  }
                />
              </FileManager>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default SeduteAmministrative;
export { SeduteAmministrative, FascicoliSeduteAmministrative, FascicoloDetail, BozzeFascicoliSeduteAmministrative};
