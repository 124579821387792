import React, { Component } from 'react';
import { redirectTo } from "@reach/router";
import $ from 'jquery';

import config from './config/config';

class Login extends Component {
    constructor(props) {
      super(props);
      this.state = {
        username: '',
        password: ''
      };
      
      this.handleInputChange = this.handleInputChange.bind(this);
      this.doLogin = this.doLogin.bind(this);
    }
    doLogin(event) {
      event.preventDefault();

      $.ajax(config.wpRestUrl + '/jwt-auth/v1/token', {
        method: 'POST',
        data: {
          username: this.state.username,
          password: this.state.password
        },
        success: (response) => {
          window.sessionStorage.setItem('authToken', response.token);
          window.location='/';
        }
      });
    }
    handleInputChange(event) {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;

      this.setState({
        [name]: value
      });
    }
    render() {
        return (
          <div className="container login">
            <div className="row">
                <div className="col-12 text-center">
                  <div className="header-box">
                    <h2>Login</h2>
                  </div>
                </div>
            </div>
            <div className="content-section">
              <form onSubmit={this.doLogin}>
            <div className="input-group input-group-lg d-flex align-items-stretch">
                
                  <div className="col-lg-5">
                    <label>User</label>
                    <input type="text" name="username" value={this.state.username} className="form-control" onChange={this.handleInputChange} />
                  </div>
                  <div className="col-lg-5">
                    <label>Password</label>
                    <input type="password" name="password" value={this.state.password} className="form-control" onChange={this.handleInputChange} />
                  </div>
                  <div className="col-lg-2 d-flex flex-column justify-content-end">
                    <button className="btn btn-info btn-block" type="submit">login</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        );
    }
}

export default Login;
