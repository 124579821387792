import React, { Component } from 'react';


class EvRow extends Component {
    render() {
        return (
            <div>
                <a href={this.props.urlInfo + this.props.id} className="row evento mb-3">
                    <div className="figure col-2">
                        <img src={this.props.img} className="rounded wp-post-image" alt="" />
                    </div>
                    <header className="col-10">
                        <div className="data">{this.props.data}</div>
                        <h5>{this.props.title}</h5>
                        <div className="subtitle">
                            {this.props.subtitle}
                        </div>
                        <div className="citta">
                            {this.props.citta}
                        </div>
                        <span className="under-title"></span>
                    </header>
                </a>
            </div>
        );
    }
}

class EvDetail extends Component {
    render() {
        return (
            
            <div>
                <div className="row evento mb-3">
                    <div data-id={this.props.id}>
                        <div className="figure col-2">
                            <img src={this.props.img} className="rounded wp-post-image" alt="" />
                        </div>
                        <header className="col-10">
                            <div className="data">{this.props.data}</div>
                            <h5>{this.props.title}</h5>
                            <div className="subtitle">
                                {this.props.subtitle}
                            </div>
                            <div className="citta">
                                {this.props.citta}
                            </div>
                            <span className="under-title"></span>
                        </header>
                    </div>
                </div>
                <div className="row evento mb-3">
                    <div className="col-12">
                        {this.props.content}
                    </div>
                </div>
            </div>
        );
    }
}

export default EvRow;
export { EvRow, EvDetail};
