import React, { Component } from 'react';
import HeaderUser from './header-user.js';
import Home from './home.js';
import Login from './login.js';
import { FascicoliSeduteAmministrative, FascicoloDetail, BozzeFascicoliSeduteAmministrative } from './sedute-amministrative.js';
import { VerbaliCommissioni, VerbaleDetail} from './verbali-commissioni.js';
import { OnpgCommissioni, OnpgOsservatoriLocali, OnpgCalendario, OnpgDetail} from './onpg.js';
import { AslContent, AslGuidaGiuridica, AslReportageGiuridico, AslGuidaGiuridicaDetail, AslReportageGiuridicoDetail, AslStartUp, AslStartUpDetail, AslStudioLegaleInAula, AslStudioLegaleInAulaDetail, AslEsperienze, AslEsperienzeDetail, AslProposte, AslProposteDetail} from './alternanza-scuola-lavoro.js';
import { Router } from "@reach/router";

import './App.scss';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authToken: window.sessionStorage.getItem('authToken')
    }
  }
  render() {
    let appJSX;
    if(null === this.state.authToken) {
      appJSX = <Login authTokenCallback={this.setAuthToken} />;
    } else {
      appJSX =
        <Router>
          <Home path="/my-cnf" default />
          <FascicoliSeduteAmministrative path="/seduteamministrative/fascicoli" />
          <FascicoloDetail path="/seduteamministrative/fascicoli/:fascicoloId" />
          <BozzeFascicoliSeduteAmministrative path="/seduteamministrative/bozze" />
          <VerbaliCommissioni path="/verbalicommissione/verbali" />
          <VerbaleDetail path="/verbalicommissione/verbali/:verbaleId" />
          <OnpgCommissioni path="/onpg/commissioni" />
          <OnpgDetail path="/onpg/commissioni/:commissioneId" />
          <OnpgOsservatoriLocali path="/onpg/osservatori-locali" />
          <OnpgCalendario path="/onpg/calendario-eventi" />
          <AslContent path="/alternanza-scuola-lavoro" />
          <AslGuidaGiuridica path="/alternanza-scuola-lavoro/guida-giuridica" />
          <AslGuidaGiuridicaDetail path="/alternanza-scuola-lavoro/guida-giuridica/:docId" />
          <AslReportageGiuridico path="/alternanza-scuola-lavoro/reportage-giuridico" />
          <AslReportageGiuridicoDetail path="/alternanza-scuola-lavoro/reportage-giuridico/:docId" />
          <AslStartUp path="/alternanza-scuola-lavoro/start-up" />
          <AslStartUpDetail path="/alternanza-scuola-lavoro/startup/:docId" />
          <AslStudioLegaleInAula path="/alternanza-scuola-lavoro/studio-legale-in-aula" />
          <AslStudioLegaleInAulaDetail path="/alternanza-scuola-lavoro/studio-legale-in-aula/:docId" />
          <AslEsperienze path="/alternanza-scuola-lavoro/esperienze" />
          <AslEsperienzeDetail path="/alternanza-scuola-lavoro/esperienze/:docId" />
          <AslProposte path="/alternanza-scuola-lavoro/proposte" />
          <AslProposteDetail path="/alternanza-scuola-lavoro/proposte/:docId" />
        </Router>
      ;
    }

    return (
      <div className="App">
        <HeaderUser />
        {appJSX}
      </div>
    );
  }
}


export default App;
