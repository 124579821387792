import React, { Component } from 'react';
import bookoblique from './icon/ico_cnf_ext-17.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { FileNavigator, FileManager } from '@opuscapita/react-filemanager';
import connectorNodeV1 from '@opuscapita/react-filemanager-connector-node-v1';
import config from './config/config';

class SeduteAmministrative extends Component {
    render() {
        return (
            <div className="section">
                <div className="col-12 text-center header-box">
                    <h2>Verbali delle commissioni</h2>
                </div>
                <div className="content-section">
                    <a href="/verbalicommissione/verbali" className="row align-items-center">
                        <div className="col-md-2">
                            <img src={bookoblique} className="ico-standard" alt="Icona libro" />
                        </div>
                        <div className="col-md-9 px-md-0">
                            <h4>Sfoglia i verbali</h4>
                            Archivio dei verbali emessi, ordinati per data
                        </div>
                        <div className="col-md-1">
                            <FontAwesomeIcon icon={faLongArrowAltRight} className="ico-standard" />
                        </div>
                    </a>
                </div>
            </div>
        );
    }
}

class VerbaliCommissioni extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fascicoli: [
                {
                    id: 10,
                    title: '22 febbraio 2019 - fascicolo seduta amministrativa'
                },
                {
                    id: 20,
                    title: '10 febbraio 2019 - fascicolo seduta amministrativa'
                },
                {
                    id: 22,
                    title: '32 febbraio 2019 - fascicolo seduta amministrativa'
                }
            ]
        };
    }
    render() {
      const rootId = 'L2NuZi9BcmVhIHJpc2VydmF0YS9WZXJiYWxpIGRlbGxlIENvbW1pc3Npb25pIENORg';
      return(
        <div className="App verbali-commissioni">
          <div className="container">
            <div className="row">
                <div className="col-12">
                  <div className="text-center header-box">
                  <h3>Verbali delle commissioni</h3>
                  <h2>Verbali</h2>
                  </div>
                </div>
            </div>
            <div className="content-section">
              <div style={{ height: '480px' }}>
                <FileManager>
                  <FileNavigator
                    id="filemanager-1"
                    api={connectorNodeV1.api}
                    apiOptions={config.filemanager.apiOptions}
                    initialResourceId={rootId}
                    capabilities={connectorNodeV1.capabilities}
                    listViewLayout={connectorNodeV1.listViewLayout}
                    viewLayoutOptions={connectorNodeV1.viewLayoutOptions}
                    onResourceItemDoubleClick={
                      ({event, number, rowData}) => {
                        const isFile = rowData.type === 'file';

                        if(isFile)
                        {
                          const downloadUrl = `${config.filemanager.apiOptions.apiRoot}/download?items=${rowData.id}`;
        //                  const name = rowData.name;
        //                  if (downloadUrl) {
        //                    var iframeId = 'cnf-iframe-downloader';
        //                    var iframeDOMNode = document.getElementById(iframeId);
        //
        //                    if (!iframeDOMNode) {
        //                      iframeDOMNode = document.createElement('iframe');
        //                      iframeDOMNode.style.display = 'none';
        //                      iframeDOMNode.id = iframeId;
        //                      document.body.appendChild(iframeDOMNode);
        //                    }
        //
        //                    iframeDOMNode.src = downloadUrl;
        //                  }
                          window.open(downloadUrl + '&preview=true', '_blank');
                        }
                      }
                    }
                  />
                </FileManager>
              </div>
            </div>
          </div>
        </div>

      );
    }
}

class VerbaleRow extends Component {
    render() {
        return (
            <tr>
                <td data-id={this.props.id}><a href={'/verbalicommissione/verbali/' + this.props.id}>{this.props.title}</a></td>
            </tr>
        );
    }
}

class VerbaleDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            verbale: {
                title: 'Title',
                content: 'Lorem ipsum sit dolor amet'
            }
        };
    }
    render() {
        return (
            <div>
                <h1>{this.state.verbale.title}</h1>
                <p>{this.state.verbale.content}</p>
            </div>
        );
    }
}

export default SeduteAmministrative;
export { SeduteAmministrative, VerbaliCommissioni, VerbaleRow, VerbaleDetail};
