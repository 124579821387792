import React, { Component } from 'react';
import bookoblique from './icon/ico_cnf_ext-17.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

class DettRow extends Component {
    render() {
        return (
            <div>
                <a href={this.props.urlInfo + this.props.id} className="row align-items-center">
                    <div className="col-md-2">
                        <img src={bookoblique} className="ico-standard" alt="Icona libro" />
                    </div>
                    <div className="col-md-9 px-md-0">
                      <h4 dangerouslySetInnerHTML={{ __html: this.props.title }} />
                    </div>
                    <div className="col-md-1">
                        <FontAwesomeIcon icon={faLongArrowAltRight} className="ico-standard" />
                    </div>
                </a>
                <hr></hr>
            </div>
        );
    }
}

class DettDetail extends Component {

    render() {
        return (
            <div>
                <div data-id={this.props.id}>
                  <h1 dangerouslySetInnerHTML={{ __html: this.props.title }} />
                  <div dangerouslySetInnerHTML={{ __html: this.props.content }} />
                  <div className="py-3">
                    <a href="/alternanza-scuola-lavoro" className="btn btn-info">Modifica &gt;</a>
                  </div>
                </div>
            </div>
        );
    }
}

export default DettRow;
export { DettRow, DettDetail};
