import React, { Component } from 'react';
import bookoblique from './icon/ico_cnf_ext-17.svg';
import world from './icon/ico_cnf_ext-26.svg';
import calendar from './icon/ico_cnf_ext-10.svg';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLongArrowAltRight} from '@fortawesome/free-solid-svg-icons';
import {EvRow, EvDetail} from './events-simple.js';
import { DettRow, DettDetail } from './doc-simple.js';
import $ from "jquery";
import config from './config/config';

class Onpg extends Component {
    render() {
        return (
          <div className="section">
            <div className="row">
                <div className="col-12 text-center">
                  <div className="header-box">
                    <h2>ONPG</h2>
                  </div>
                </div>
            </div>
            <div className="content-section">
              <a href="/onpg/commissioni" className="row align-items-center py-4 border-bottom">
                <div className="ico col-lg-1 text-center text-lg-left">
                  <img src={bookoblique} className="ico-standard" alt="Icona libro" />
                </div>
                <div className="col-lg-9 text-center text-lg-left">
                  <h4>Commissioni</h4>
                  Componenti, verbali, documenti di studio
                </div>
                <div className="col-lg-2 text-center text-lg-right">
                  <FontAwesomeIcon icon={faLongArrowAltRight} />
                </div>
              </a>
              <a href="/onpg/osservatori-locali" className="row align-items-center py-4">
                <div className="ico col-lg-1 text-center text-lg-left">
                  <img src={world} className="ico-standard" alt="Icona mondo" />
                </div>
                <div className="col-lg-9 text-center text-lg-left">
                  <h4>Osservatori locali</h4>
                </div>
                <div className="col-lg-2 text-center text-lg-right">
                  <FontAwesomeIcon icon={faLongArrowAltRight} />
                </div>
              </a>
              {/*<div className="events row">
                <div className="col-12 container-events">
                  <a href="/onpg/calendario-eventi" className="row align-items-center py-4">
                    <div className="ico col-lg-1 text-center text-lg-left">
                    <img src={calendar} className="ico-standard" alt="Icona eventi" />
                    </div>
                    <div className="col-lg-9 text-center text-lg-left">
                      <h4>Calendario eventi</h4>
                    </div>
                    <div className="col-lg-2 text-center text-lg-right">
                      <FontAwesomeIcon icon={faLongArrowAltRight} />
                    </div>
                  </a>
                  <OnpgCalendariosmall />
                </div>
              </div>*/}
            </div>
          </div>
        );
    }
}


class OnpgCommissioni extends Component {
    constructor(props) {
        super(props);
        this.state = {
            commissioni: [
                {
                    id: 10,
                    title: '22 febbraio 2019 - fascicolo seduta amministrativa'
                },
                {
                    id: 20,
                    title: '10 febbraio 2019 - fascicolo seduta amministrativa'
                },
                {
                    id: 22,
                    title: '32 febbraio 2019 - fascicolo seduta amministrativa'
                }
            ]
        };
    }
    componentDidMount() {
      $.ajax('https://cnf.stage.de.dol.it/wp-json/wp/v2/posts?include=13165,12428,13167,13440,13600,12825,12993&orderby=title&order=asc', {
        success: (response) => {
          if(response.length < 7)
          {
            console.log('NOBUONO');
          }
          else
          {
            console.log(response);
            this.setState({commissioni: response});
          }
        }
      });
    }
    render() {
      const baseUrl = '/onpg/commissioni/';
        return (
          <div className="App onpg">
            <div className="container">
              <div className="row">
                  <div className="col-12">
                    <div className="text-center header-box">
                      <h3>ONPG</h3>
                      <h2>Commissioni</h2>
                    </div>
                  </div>
              </div>
              <div className="content-section">
                {this.state.commissioni.map((commissione, i) => <DettRow urlInfo={baseUrl} id={commissione.id} title={commissione.title.rendered} />)}
              </div>
            </div>
          </div>
        );
    }
}

class OnpgOsservatoriLocali extends Component {
    constructor(props) {
        super(props);
        this.state = {
            osservatori: [
                {
                  id: 10,
                  urlInfo: '/seduteamministrative/fascicoli/',
                  title: '22 febbraio 2019 - fascicolo seduta amministrativa'
                },
                {
                  id: 20,
                  urlInfo: '/seduteamministrative/fascicoli/',
                  title: '10 febbraio 2019 - fascicolo seduta amministrativa'
                },
                {
                  id: 22,
                  urlInfo: '/seduteamministrative/fascicoli/',
                  title: '32 febbraio 2019 - fascicolo seduta amministrativa'
                }
            ]
        };
    }
    componentDidMount() {
      this.setState({osservatori: []});
    }
    render() {
        return (
          <div className="App onpg">
            <div className="container">
              <div className="row">
                  <div className="col-12">
                    <div className="text-center header-box">
                      <h3>ONPG</h3>
                      <h2>Osservatori locali</h2>
                    </div>
                  </div>
              </div>
              <div className="content-section">
                {this.state.osservatori.length > 0 ? (
                  this.state.osservatori.map((osservatorio, i) => <DettRow urlInfo={osservatorio.urlInfo} id={osservatorio.id} title={osservatorio.title.rendered} />)
                ) : (
                  'Nessun contenuto presente'
                )}
              </div>
            </div>
          </div>
        );
    }
}



class OnpgCalendariosmall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventssmall: [
        {
          id: 10,
          urlInfo: '/onpg/calendario/',
          title: '22 febbraio 2019 - evento seduta amministrativa',
          img: 'https://cnf.stage.de.dol.it/wp-content/uploads/2016/10/corsi-png.png',
          subtitle: 'sottotitolo',
          data: '30 Ottobre 2018',
          citta: 'Roma'
        },
        {
          id: 20,
          urlInfo: '/onpg/calendario/',
          title: '10 febbraio 2019 - evento seduta amministrativa',
          img: 'https://cnf.stage.de.dol.it/wp-content/uploads/2016/10/corsi-png.png',
          subtitle: 'sottotitolo',
          data: '30 Ottobre 2018',
          citta: 'Roma'
        },
        {
          id: 22,
          urlInfo: '/onpg/calendario/',
          title: '32 febbraio 2019 - evento seduta amministrativa',
          img: 'https://cnf.stage.de.dol.it/wp-content/uploads/2016/10/corsi-png.png',
          subtitle: 'sottotitolo',
          data: '30 Ottobre 2018',
          citta: 'Roma'
        }
      ]
    };
  }
  render() {
    return (
        <div className="content-section">
        {this.state.eventssmall.map((eventosm, i) => <EvRow urlInfo={eventosm.urlInfo} id={eventosm.id} title={eventosm.title} subtitle={eventosm.subtitle} img={eventosm.img} data={eventosm.data} citta={eventosm.citta} />)}
        </div>
    );
  }
}

class OnpgCalendario extends Component {
    constructor(props) {
        super(props);
        this.state = {
          eventi: [
                {
                  id: 10,
                  urlInfo: '/onpg/calendario/',
                  title: '22 febbraio 2019 - fascicolo seduta amministrativa',
                  img: 'https://cnf.stage.de.dol.it/wp-content/uploads/2016/10/corsi-png.png',
                  subtitle: 'sottotitolo',
                  data: '30 Ottobre 2018',
                  citta: 'Roma'
                },
                {
                  id: 20,
                  urlInfo: '/onpg/calendario/',
                  title: '10 febbraio 2019 - fascicolo seduta amministrativa',
                  img: 'https://cnf.stage.de.dol.it/wp-content/uploads/2016/10/corsi-png.png',
                  subtitle: 'sottotitolo',
                  data: '30 Ottobre 2018',
                  citta: 'Roma'
                },
                {
                  id: 22,
                  urlInfo: '/onpg/calendario/',
                  title: '32 febbraio 2019 - fascicolo seduta amministrativa',
                  img: 'https://cnf.stage.de.dol.it/wp-content/uploads/2016/10/corsi-png.png',
                  subtitle: 'sottotitolo',
                  data: '30 Ottobre 2018',
                  citta: 'Roma'
                }
            ]
        };
    }
    render() {
        return (
          <div className="App onpg">
            <div className="container">
              <div className="row">
                  <div className="col-12">
                    <div className="text-center header-box">
                      <h3>ONPG</h3>
                      <h2>Calendario Eventi</h2>
                    </div>
                  </div>
              </div>
              <div className="content-section">
                {this.state.eventi.map((evento, i) => <EvRow urlInfo={evento.urlInfo} id={evento.id} title={evento.title} subtitle={evento.subtitle} img={evento.img} data={evento.data} citta={evento.citta} />)}
              </div>
            </div>
          </div>
        );
    }
}


class OnpgRow extends Component {
    render() {
        return (
            <tr>
                <td data-id={this.props.id}><a href={'/onpg/commissioni/' + this.props.id}>{this.props.title}</a></td>
            </tr>
        );
    }
}

class OnpgDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            commissione: {
                title: 'Pippo',
                content: 'Lorem ipsum sit dolor amet'
            }
        };
    }
    componentDidMount() {
      $.ajax('https://cnf.stage.de.dol.it/wp-json/wp/v2/posts/' + this.props.commissioneId, {
        success: (response) => {
          console.log(response);
          this.setState({
            commissione: response
          });
        }
      });
    }
    render() {
        return (
          <div className="App sedute-amministrative">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="text-center header-box">
                    <h3>Onpg</h3>
                    <h2>Onpg</h2>
                  </div>
                </div>
              </div>
              <div className="content-section">
                <DettDetail content={this.state.commissione.content.rendered} title={this.state.commissione.title.rendered} />
              </div>
            </div>
          </div>
        );
    }
}

class OnpgCalendarioDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      evento: {
        id: 10,
        title: '22 febbraio 2019 - fascicolo seduta amministrativa',
        img: 'https://cnf.stage.de.dol.it/wp-content/uploads/2016/10/corsi-png.png',
        subtitle: 'sottotitolo',
        data: '30 Ottobre 2018',
        citta: 'Roma',
        content: { rendered: 'lorem ipsum'} 
      }
    };
  }
  render() {
    return (
      <div className="App sedute-amministrative">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="text-center header-box">
                <h3>ONPG</h3>
                <h2>Evento</h2>
              </div>
            </div>
          </div>
          <div className="content-section">
            <EvDetail content={this.state.evento.content.rendered} id={this.state.evento.id.rendered} title={this.state.evento.title.rendered} subtitle={this.state.evento.subtitle.rendered} img={this.state.evento.img.rendered} data={this.state.evento.data.rendered} citta={this.state.evento.citta.rendered} />
          </div>
        </div>
      </div>
    );
  }
}

export default Onpg;
export { Onpg, OnpgCommissioni, OnpgOsservatoriLocali, OnpgCalendario, OnpgCalendariosmall, OnpgRow, OnpgDetail, OnpgCalendarioDetail};
