import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faAngleDown, faHome } from '@fortawesome/free-solid-svg-icons';
class HeaderUser extends Component {
    render() {
        return (
            <div className="header-user">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h1>Area riservata</h1>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-10 text-right">
                                    BENVENUTO/A
                                    {/*<h5>Pinca Pancolina</h5>*/}
                                </div>
                                <div className="col-md-2">
                                    <FontAwesomeIcon icon={faUserCircle} /> <FontAwesomeIcon icon={faAngleDown} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <FontAwesomeIcon icon={faHome} /> <a href="/my-cnf">HOME BACHECA</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HeaderUser;
